/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-06 21:16:05
 * @LastEditTime: 2022-04-12 23:17:35
 */
/**
 * 封装 axios 请求模块
 */
import axios from 'axios'
import { _isMobile, getCookie } from '@/utils/util'
const CryptoJS = require('crypto-js')
const secretKey = 'rhDaeJnL8x'
const instance = axios.create()
const Base64 = require('js-base64').Base64
const openId = getCookie('openId')

const whiteList = [
  'of6Jg50MuyEbYCOC9ZmqVvvDs60M',
  'of6Jg5zxAxbjT7mWNSYxFwACsMe8',
  'of6Jg5wfSMjbhwr-adxj02G9D1Ik',
  'of6Jg5_V7sfoHx7MmqF0jdBap7Qo',
  'of6Jg52GlZifkf4FJjsC0x42PR7c',
  'of6Jg5-9aGdbDCFJBR_TQWpRu9Os',
  'of6Jg5423tE9nOk4p-AtJ7IK4aQ4',
  'of6Jg5y1qbuhzbk2g7sKuPEj_4e0',
  'of6Jg58WnrKcnWmWrzOglQ2hmevU',
  'of6Jg5yZjnlMXp6wJXpHgiDtz_S0',
  'of6Jg54HSAVWz7ZmxXHD4Xkz1v1c',
  'of6Jg59r0QpuBk0KX9V6wyJYjdhQ',
  'of6Jg53wUkURCc58_q3YR9mkPb1o',
  'of6Jg50fporTEvT_XlozrOoGvbTk',
  'of6Jg50NXStx_lP2o8B308jHjmys',
  'of6Jg5zu5ZfSfzksn8BprabeM1BM',
  'of6Jg55gbYjCu0QN0A2Od9rmy4A0',
  'of6Jg5xs3oFYlvDVUFQ8vA-J9yFM',
  'of6Jg5xf4FfnjNvohs6eozpgJUlE',
  'of6Jg5wMe7QOMj6VfLZbD4JdKK0k',
  'of6Jg58AJ0LDNunD0luNHLr1TqCo',
  'of6Jg57xt3mgAMXk-TgczA_OalY4',
  'of6Jg51YVzy50_2O4VYLY5VWJPno',
  'of6Jg58KRpSie0fKUnZsKYD3h7PM'
]

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 将token给到一个前后台约定好的key中，作为请求发送
  let userInfo = localStorage.getItem('userInfo')
  userInfo = userInfo && JSON.parse(userInfo)
  // openId && windows ** 不在白名单
  if (openId && !_isMobile() && !whiteList.includes(openId)) {
    alert('كومپيۋتەردە اشۋ ۇقىعى شەكتەلدى. قولفون نۇسقاسى ارقىلى كورىڭىز. راحىمەت.')
    return
  }
  if (userInfo?.token) {
    const base64 = Base64.encode(userInfo.token + ':')
    config.headers.Authorization = `Basic ${base64}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(res => {
  return handleResult(res.data)
}, err => {
  return Promise.reject(err)
})

// 解密数据
function handleResult (res) {
  const bytes = CryptoJS.AES.decrypt(res, secretKey)
  const result = bytes.toString(CryptoJS.enc.Utf8)
  const decryptRes = JSON.parse(result)
  return decryptRes
}

// 请求工具函数
export default instance
